.experience_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    place-items: center;
  }
  
  .experience_container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
  }
  
  .experience_container > div:hover {
    background: rgba(58, 58, 150, 0.5);
    cursor: default;
    border-color: var(--color-primary-variant);
  }
  
  .experience_container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
  }

  .experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .experience_details {
    display: flex;
    gap: 1rem;
  }

  .experience__details-icon{
    margin-top: 6px;
    color: var(--color-primary);
  }

  /* =====================MEDIA QUERIES (MEDIUM DEVICES)================================= */
@media screen and (max-width: 1024px) {
    .experience_container > div {
        width: 60%;
        padding: 2rem;
        margin: 0 auto;
      }

    .experience_content {
        padding: 1rem;
    }
  }
  
  /* =====================MEDIA QUERIES (SMALL DEVICES)================================= */

  @media screen and (max-width: 600px) {
    .experience_container > div {
        width: 90%;
        padding: 2rem 1rem;
      }
    
      .experience_content {
        grid-template-columns: 1fr;
      }
  }