/* Default styles */

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 30rem;
  padding: 8rem 2rem 2rem 10rem;
}

.header h1 {
  font-size: 36px;
}

.header h5 {
  font-size: 25px;
}

.cta {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.2rem;
}

.text-light {
  color: #aaa;
}

.me {
  background: linear-gradient(var(--color-primary), transparent);
  border-radius: 12rem 12rem 0 0;
}

.me img {
  width: 20rem;
  height: 25rem;
}

.header__socials {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  font-size: 3rem;
}

/* Media Queries */

@media screen and (min-width: 1025px) and (max-width: 1274px) {
  .header {
    grid-template-columns: 40% 90%;
    gap: 3rem;
  }

  .me {
    margin-right: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 6rem 2rem 0;
  }

  .header h1 {
    font-size: 34px;
  }

  .header h5 {
    font-size: 24px;
  }

  .cta {
    gap: 1rem;
  }

  .me img {
    width: 18rem;
    height: 22rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 4rem 1rem 0;
  }

  .header h1 {
    font-size: 32px;
  }

  .header h5 {
    font-size: 24px;
  }

  .me img {
    width: 18rem;
    height: 22rem;
  }

  .header__socials {
    font-size: 2rem;
  }
}

@media screen and (min-width: 300px) and (max-width: 310px) {
  .me img {
    width: 17rem;
    height: 22rem;
  }
}
