#contact {
    padding: 80px 0;
    text-align: center;
  }
  
  .contact__container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contact__form input,
  .contact__form textarea {
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  
  .contact__form textarea {
    resize: none;
  }
  
  /* =====================MEDIA QUERIES (MAX-WIDTH: 1024PX)========================= */
  @media screen and (max-width: 1024px) {
    .contact__container {
      padding: 0 20px;
    }
  }
  
  /* =====================MEDIA QUERIES (MAX-WIDTH: 600PX)========================== */
  @media screen and (max-width: 600px) {
    .contact__form input,
    .contact__form textarea {
      font-size: 14px;
    }
  }
  