#portfolio {
  text-align: center;
  margin: 50px auto;
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio__item {
  background: var(--color-bg-variant);
  width: 100%;
  padding: 13px;
  border-radius: 1.2rem;
  transition: var(--transition);
  border: 1px solid var(--color-primary);
}

.portfolio__item:hover {
  background: rgba(58, 58, 150, 0.5);
}

.portfolio__item-image {
  margin-bottom: 10px;
  width: 100%;
  height: 0;
  padding-bottom: 135%;
  overflow: hidden; 
  position: relative;
}

.portfolio__item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.2rem;
}


.portfolio__item h3 {
  font-size: 20px;
  padding: 1rem;
}

.button {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.button a {
  margin-right: 10px;
}

/* =====================MEDIA QUERIES (MEDIUM DEVICES)================================= */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr; 
    max-width: 60%;
    grid-gap: 20px;
  }

  .portfolio__item {
    padding: 12px;
  }

  .portfolio__item h3 {
    font-size: 18px; 
  }

  .button a {
    font-size: 18px;
    padding: 8px 12px;
  }
}

/* =====================MEDIA QUERIES (SMALL DEVICES)================================= */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr; 
    max-width: 80%;
    grid-gap: 10px; 
  }
  .portfolio__item-image {
    height: 16rem; 
  }

  .portfolio__item h3 {
    font-size: 14px; 
  }

  .button a {
    font-size: 16px;
    padding: 6px 10px;
  }

  .portfolio__item {
    padding: 10px;
  }
}
