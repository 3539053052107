nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: rgba(66, 153, 216, 0.843);
  color: var(--color-white);
}

/* Media Queries */

@media screen and (max-width: 1024px) {
  nav {
    padding: 0.5rem 1rem;
  }
  
  nav a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  nav {
    padding: 0.4rem 0.8rem;
  }
  
  nav a {
    font-size: 0.9rem;
    padding: 0.8rem;
  }
}
